'use client';

import { LanguageLink } from '@graphql/generated-contentful/graphql';
import { useChangeLocale } from '@lib/hooks/useChangeLocale';
import { FooterMenuItem } from './FooterMenu/FooterMenuItem';
import { FooterMenuLabel } from './FooterMenu/FooterMenuLabel';

type FooterLanguageMenuListProps = {
  label?: string;
  languages?: LanguageLink[];
};

export const FooterLanguageMenuList = ({
  label,
  languages,
}: FooterLanguageMenuListProps) => {
  const handleChangeLocale = useChangeLocale();
  if (!languages) {
    return null;
  }

  return (
    <ul className="col-span-6 md:col-span-4 lg:col-span-2">
      <FooterMenuItem>
        {label && <FooterMenuLabel text={label} />}
        <ul>
          {languages &&
            languages?.length > 0 &&
            languages.map((language) => {
              return (
                <FooterMenuItem key={language.title}>
                  <button
                    className="focus-outline rounded text-grey-500 hover:text-blue-dark"
                    data-test={`language-${language.locale!}`}
                    onClick={() => handleChangeLocale(language.locale!)}
                  >
                    {language.title}
                  </button>
                </FooterMenuItem>
              );
            })}
        </ul>
      </FooterMenuItem>
    </ul>
  );
};
