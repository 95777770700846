'use client';

import { useEffect, useState } from 'react';

type RenderClientSideProps = {
  children: React.ReactNode;
  fallback?: React.ReactNode;
};

export const RenderClientSide = ({
  children,
  fallback = null,
}: RenderClientSideProps) => {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);
  return <>{isMounted ? children : fallback}</>;
};
